import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    SimpleNote: westack {
      tool(id: "simplenote") {
        ...ToolInfo
      }
    }
    RustDesk: westack {
      tool(id: "rustdesk") {
        ...ToolInfo
      }
    }
    Helix: westack {
      tool(id: "helix") {
        ...ToolInfo
      }
    }
    Kalk: westack {
      tool(id: "kalk") {
        ...ToolInfo
      }
    }
    GDU: westack {
      tool(id: "gdu") {
        ...ToolInfo
      }
    }
    Node_Media_Server: westack {
      tool(id: "node-media-server") {
        ...ToolInfo
      }
    }
    GQless: westack {
      tool(id: "gqless") {
        ...ToolInfo
      }
    }
    PainterEngine: westack {
      tool(id: "painterengine") {
        ...ToolInfo
      }
    }
    Farrow: westack {
      tool(id: "farrow") {
        ...ToolInfo
      }
    }
    Umami: westack {
      tool(id: "umami") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.SimpleNote.tool} mdxType="Tool" />
    <Tool tool={props.data.RustDesk.tool} mdxType="Tool" />
    <Tool tool={props.data.Helix.tool} mdxType="Tool" />
    <Tool tool={props.data.Kalk.tool} mdxType="Tool" />
    <Tool tool={props.data.GDU.tool} mdxType="Tool" />
    <Tool tool={props.data.Node_Media_Server.tool} mdxType="Tool" />
    <Tool tool={props.data.GQless.tool} mdxType="Tool" />
    <Tool tool={props.data.PainterEngine.tool} mdxType="Tool" />
    <Tool tool={props.data.Farrow.tool} mdxType="Tool" />
    <Tool tool={props.data.Umami.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      